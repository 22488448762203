import revive_payload_client_d5CU3yEZCe from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_im0H5pxtfY from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RHJi9qBGaN from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_LAibwvK3kK from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.4_magicast@0.3.5_rollup@4.27.4_vite@5.4.11_@types+node@22.10.0_terser@5._p7cyn5t3kh2cpid7eaimdgg66a/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_T15Px1nJBI from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_rHmzBdK6Rv from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OJn4ZSVTTy from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_oLavauGPGD from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/docs/.nuxt/components.plugin.mjs";
import prefetch_client_szgh6pBO2e from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_JGUISdi9wD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.27.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/docs/.nuxt/unocss.mjs";
import theme_client_UjsvwBhNji from "/vercel/path0/node_modules/.pnpm/@una-ui+nuxt-edge@0.30.0-beta.1-28880710.a2fc753_@unocss+preset-wind@0.64.1_@unocss+webpack@0_lome4ucbmnovtd5wtcrl3yl6dy/node_modules/@una-ui/nuxt-edge/dist/runtime/plugins/theme.client.js";
import plugin_YOGvVx2IRB from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.8.2_magicast@0.3.5_rollup@4.27.4_vite@5.4.11_@types+node@22.10.0_terser@5.36.0___77pq5h5mow2cl6n3fvsoztucfa/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_uhw4qt9eZB from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_ioredis@5.4.1_magicast@0.3.5_nuxt@3.14.1592_@parcel+watcher@2.5.0_@types_uanuuyx4ybvbc6n7d3kzgfq25i/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
export default [
  revive_payload_client_d5CU3yEZCe,
  unhead_im0H5pxtfY,
  router_RHJi9qBGaN,
  _0_siteConfig_LAibwvK3kK,
  payload_client_T15Px1nJBI,
  navigation_repaint_client_rHmzBdK6Rv,
  check_outdated_build_client_OJn4ZSVTTy,
  chunk_reload_client_oLavauGPGD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_szgh6pBO2e,
  plugin_client_JGUISdi9wD,
  unocss_MzCDxu9LMj,
  theme_client_UjsvwBhNji,
  plugin_YOGvVx2IRB,
  documentDriven_uhw4qt9eZB
]