import { default as _91_46_46_46slug_93ixiWOPbEO9Meta } from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.0_@unocss+preset-wind@0.64.1_@_w6zwrqsmmrndtduvs2mcsd7a7a/node_modules/@una-ui/content/pages/[...slug].vue?macro=true";
import { default as indexNMXuNyMaQiMeta } from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.0_@unocss+preset-wind@0.64.1_@_w6zwrqsmmrndtduvs2mcsd7a7a/node_modules/@una-ui/content/pages/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.0_@unocss+preset-wind@0.64.1_@_w6zwrqsmmrndtduvs2mcsd7a7a/node_modules/@una-ui/content/pages/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.0_@unocss+preset-wind@0.64.1_@_w6zwrqsmmrndtduvs2mcsd7a7a/node_modules/@una-ui/content/pages/index.vue")
  }
]