import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.0_eslint@9.15.0_jiti@2.4.0__ioredis@5._n3sq6et333knaemwmsvnrkgdqu/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.10.0_@unocss+preset-wind@0.64.1_@_w6zwrqsmmrndtduvs2mcsd7a7a/node_modules/@una-ui/content/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}